import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import AddToCart from "../components/global/ModalCart"

import ContentfulReachContent from "../components/global/ContentfulReachContent"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"

import { BsCheckCircle } from "react-icons/bs"
import { FiMinusCircle } from "react-icons/fi"
import { RiShoppingCartLine, RiTruckLine } from "react-icons/ri"
// import { VscDebugDisconnect } from "react-icons/vsc"
import { FaHryvnia } from "react-icons/fa"

import { AiOutlineFileProtect } from "react-icons/ai"

export const query = graphql`
  query($slug: String!) {
    product: contentfulShopProduct(seo: { slug: { eq: $slug } }) {
      id
      productName
      productPrice
      isInStock
      productDescription {
        json
      }
      productImage {
        title
        fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 800, height: 800) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      seo {
        slug
        title
        metaDescription
        pageImage {
          fluid(maxWidth: 620) {
            src
          }
        }
      }
      productCategory {
        categoryName
        seo {
          slug
        }
      }
    }
  }
`

const ProductTemplate = ({ data: { product } }) => {
  return (
    <Layout>
      <SEO
        title={product.seo.title}
        description={product.seo.metaDescription}
        image={`https:${product.seo.pageImage.fluid.src}`}
        url={`/${product.productCategory.seo.slug}/${product.seo.slug}/`}
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/shop/">Обладнання</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${product.productCategory.seo.slug}/`}>
                  {product.productCategory.categoryName}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {product.productName}
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">{product.productName}</h1>
          {/* <div className="hero-slogan mt-4 d-flex align-items-center">
            <div className="shop-product-price">
              <span class="gradient-text">{product.productPrice}</span>
              <span class="price-currency">грн</span>
            </div>
            {product.isInStock === true ? (
              <div className="shop-product-availability">
                <BsCheckCircle /> Є в наявності
              </div>
            ) : (
              <div className="shop-product-availability text-muted">
                <FiMinusCircle /> Немає в наявності
              </div>
            )}
          </div>
          <div className="slogan-button my-3">
            {product.isInStock ? (
              <AddToCart
                productImage={product.productImage.fluid}
                productTitle={product.productName}
                productPrice={product.productPrice}
                pageSlug={`/${product.productCategory.seo.slug}/${product.seo.slug}/`}
                pageTitle={product.productName}
              />
            ) : (
              <Button
                className="shop-product-button"
                variant="secondary"
                size="lg"
                disabled
              >
                <RiShoppingCartLine /> Не продається
              </Button>
            )}
          </div> */}
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={6} className="h-100 p-3">
            <Img
              fluid={product.productImage.fluid}
              alt={product.productImage.title}
            />
          </Col>
          <Col lg={6} className="mt-3">
            <div className="my-5">
              <div className="shop-product-price my-3">
                {product.productPrice} грн
              </div>
              {product.isInStock === true ? (
                <div className="shop-product-availability">
                  <BsCheckCircle /> є в наявності
                </div>
              ) : (
                <div className="text-muted">
                  <FiMinusCircle /> немає в наявності
                </div>
              )}

              {product.isInStock ? (
                // <Button
                //   className="shop-product-button"
                //   variant="warning"
                //   size="lg"
                // >
                //   <RiShoppingCartLine /> Купити
                // </Button>
                <AddToCart
                  productImage={product.productImage.fluid}
                  productTitle={product.productName}
                  productPrice={product.productPrice}
                  pageSlug={`/${product.productCategory.seo.slug}/${product.seo.slug}/`}
                  pageTitle={product.productName}
                />
              ) : (
                <Button
                  className="shop-product-button my-3"
                  variant="secondary"
                  size="lg"
                  disabled
                >
                  <RiShoppingCartLine /> Не продається
                </Button>
              )}
            </div>
            <ContentfulReachContent
              contentJSON={product.productDescription.json}
            />
            {/* <div>
                {documentToReactComponents(product.productDescription.json, {
                  renderNode: {
                    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                      <img
                        src={`${node.data.target.fields.file["uk"].url}?w=500`}
                        alt={node.data.target.fields.title["uk"]}
                      />
                    ),
                  },
                })}
              </div> */}
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Card className="shop-product-conditions-card my-3">
              <Card.Body>
                <Card.Title className="mb-3 gradient-text">
                  <RiTruckLine className="mr-3" />
                  Доставка
                </Card.Title>

                <ul className="shop-delivery-list mb-3 list-unstyled">
                  <li className="shop-product-delivery-option">
                    <div className="shop-delivery-type">
                      Доставка для абонентів
                    </div>
                    {/* <div className="shop-delivery-divider"></div> */}
                    <div className="gradient-text shop-delivery-price">
                      200 грн
                    </div>
                  </li>
                  <li className="shop-product-delivery-option">
                    <div className="shop-delivery-type">
                      Доставка при підключенні
                    </div>
                    {/* <div className="shop-delivery-divider"></div> */}
                    <div className="gradient-text shop-delivery-price">
                      безкоштовно
                    </div>
                  </li>
                  <li className="shop-product-delivery-option">
                    <div className="shop-delivery-type">Самовивіз з офісу</div>
                    {/* <div className="shop-delivery-divider"></div> */}
                    <div className="gradient-text shop-delivery-price">
                      безкоштовно
                    </div>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card className="shop-product-conditions-card my-3">
              <Card.Body>
                <Card.Title className="mb-3 gradient-text">
                  <FaHryvnia className="mr-3" />
                  Оплата
                </Card.Title>
                <Card.Text as="ul" className="list-unstyled">
                  <li>Оплата через особистий кабінет абонента</li>
                  <li>Оплата карткою</li>
                  <li>Оплата готівкою в офісі кампанії</li>
                  <li>Оплата на розрахунковий рахунок</li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card className="shop-product-conditions-card my-3">
              <Card.Body>
                <Card.Title className="mb-3 gradient-text">
                  <AiOutlineFileProtect className="mr-3" />
                  Гарантія
                </Card.Title>
                <Card.Text>
                  Обмін або повернення товару протягом 14 днів
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ProductTemplate
